import * as React from "react";
import { Routes, Route } from "react-router-dom";
import AppointmentSearch from "../pages/AppointmentSearch";
import Capture from "../pages/Capture";
import ChooseData from "../pages/ChooseData";
import HelpPage from "../pages/HelpPage";
import ChooseDoctor from "../pages/ChooseDoctor";
import ChooseHour from "../pages/ChooseHour";
import ChooseSpecialty from "../pages/ChooseSpecialty";
import NewAppointmentConfirmation from "../pages/NewAppointmentConfirmation";
import ConfirmationDataToFaceRegistration from "../pages/ConfirmationDataToFaceRegistration";
import FeedbackConfirmation from "../pages/FeedbackConfirmation";
import PatientAppointment from "../pages/PatientAppointment";
import PreFaceCapture from "../pages/PreFaceCapture";
import Scheduling from "../pages/Scheduling";
import NewAppointmentFeedback from "../pages/NewAppointmentFeedback";
import SignIn from "../pages/SignIn";
import ChooseCapsule from "../pages/ChooseCapsule";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ChooseCapsule />} />
      <Route path="/inicio" element={<SignIn />} />
      <Route path="/captura" element={<Capture />} />
      <Route path="/pesquisa-consulta" element={<AppointmentSearch />} />
      <Route path="/confirmacao-feedback" element={<FeedbackConfirmation />} />
      <Route path="/consulta-paciente" element={<PatientAppointment />} />
      <Route path="/confirmacao-registro" element={<ConfirmationDataToFaceRegistration />} />
      <Route path="/pre-captura" element={<PreFaceCapture />} />
      <Route path="/agendamento" element={<Scheduling />} />
      <Route path="/escolher-especialidade" element={<ChooseSpecialty />} />
      <Route path="/escolher-medico" element={<ChooseDoctor />} />
      <Route path="/escolher-data" element={<ChooseData />} />
      <Route path="/escolher-hora" element={<ChooseHour />} />
      <Route path='/novo-agendamento' element={<NewAppointmentConfirmation />} />
      <Route path='/confirmacao-nova-consulta' element={<NewAppointmentFeedback />} />
      <Route path='/ajuda' element={<HelpPage />} />
    </Routes>
  );
}

export default AppRoutes;