import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ChooseDoctorComponent: React.FC<any> = ({
  doctorNames,
  selectedDoctor,
  doctorOne,
  doctorTwo,
  doctorThree,
  isDisabledMinus,
  isDisabledPlus,
  changeDoctorMinus,
  changeDoctorPlus,
  showDoctorName,
  setIsDisabled
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ pt: 3, pl: 3, pr: 3, pb: 5 }}
      >
        <Grid container>
          <Button onClick={() => navigate("../inicio", { replace: true })}>
            <Grid item sx={{ pr: 0.7, pt: 1 }}>
              <ArrowBackIcon fontSize="small" />
            </Grid>
            <Grid item sx={{ pt: 0.2 }}>
              <p>VOLTAR</p>
            </Grid>
          </Button>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          direction="row"
          sx={{ pl: 2, pr: 2, pt: 10, pb: 5 }}
        >
          <Grid item xs={12} sx={{ pt: 5 }}>
            <Typography variant="h1">
              Selecione o profissional de saúde
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ pt: 10 }}
          >
            <Grid
              container
              display="flex"
              textAlign="center"
              justifyContent="left"
              alignItems="center"
              xs={1.5}
            >
              <Button
                onClick={() => changeDoctorMinus()}
                disabled={isDisabledMinus}
              >
                <ArrowBackIosNewIcon style={{ color: "black" }} />
              </Button>
            </Grid>
            <Grid item textAlign="center" xs={9}>
              <Grid item xs={12} sx={{ pb: 1 }}>
                <Button
                  disabled={setIsDisabled(doctorNames[doctorOne][0])}
                  variant="text"
                  size="large"
                  fullWidth
                  style={{ height: 50 }}
                  sx={{ boxShadow: 2 }}
                  onClick={() =>
                    selectedDoctor(
                      doctorNames[doctorOne][0].idProfissional,
                      doctorNames[doctorOne][0].nomeProfissional,
                      doctorNames[doctorOne][0].agenda
                    )
                  }
                >
                  <Typography style={{ color: "black" }}>
                    {showDoctorName(doctorNames[doctorOne])}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ pb: 1 }}>
                <Button
                  disabled={setIsDisabled(doctorNames[doctorTwo][0])}
                  variant="text"
                  size="large"
                  fullWidth
                  style={{ height: 50 }}
                  sx={{ boxShadow: 2 }}
                  onClick={() =>
                    selectedDoctor(
                      doctorNames[doctorTwo][0].idProfissional,
                      doctorNames[doctorTwo][0].nomeProfissional,
                      doctorNames[doctorTwo][0].agenda
                    )
                  }
                >
                  <Typography style={{ color: "black" }}>
                    {showDoctorName(doctorNames[doctorTwo])}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ pb: 1 }}>
                <Button
                  disabled={setIsDisabled(doctorNames[doctorThree])}
                  variant="text"
                  size="large"
                  fullWidth
                  style={{ height: 50 }}
                  sx={{ boxShadow: 2 }}
                  onClick={() =>
                    selectedDoctor(
                      doctorNames[doctorThree][0].idProfissional,
                      doctorNames[doctorThree][0].nomeProfissional,
                      doctorNames[doctorThree][0].agenda
                    )
                  }
                >
                  <Typography style={{ color: "black" }}>
                    {showDoctorName(doctorNames[doctorThree])}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              display="flex"
              textAlign="center"
              justifyContent="right"
              alignItems="center"
              xs={1.5}
            >
              <Button
                onClick={() => changeDoctorPlus()}
                disabled={isDisabledPlus}
              >
                <ArrowForwardIosIcon style={{ color: "black" }} />
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            sx={{ pt: 10 }}
          >
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                style={{ borderRadius: 10 }}
                fullWidth
                onClick={() => navigate("../inicio")}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ChooseDoctorComponent;
