import React from "react";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import Webcam from "react-webcam";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PreCaptureComponent: React.FC<any> = ({
  capture,
  webcamRef,
  navigate,
  patientName,
  patientCpf,
  patientId
}) => {
  return (
    <>
      <Grid container sx={{ pl: 3, pr: 3, pt: 3 }}>
        <Button onClick={() => navigate(-1)}>
          <Grid item sx={{ pr: 0.7, pt: 1 }}>
            <ArrowBackIcon fontSize="small" />
          </Grid>
          <Grid item sx={{ pt: 0.2 }}>
            <p>VOLTAR</p>
          </Grid>
        </Button>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{ pl: 5, pr: 5, pt: 5, pb: 5 }}
      >
        <Grid item textAlign="center">
          <Typography variant="h3">
            {patientName}, vamos cadastrar sua foto em nosso sistema.
          </Typography>
        </Grid>
        <Grid item sx={{ pt: 5, pb: 5 }}>
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            ref={webcamRef}
            height={300}
            width={350}
          />
        </Grid>
        <Grid item textAlign="center">
          <Typography variant="h2">
            Por favor, posicione-se em frente à câmera e clique em CAPTURAR. Se
            estiver de máscara, o sistema irá focar na região dos olhos. Você
            pode manter o uso da máscara.
          </Typography>
        </Grid>
        <Grid item textAlign="center">
          <Typography variant="h2" sx={{ pt: 3 }}>
            Caso não queira tirar uma foto, clique em PULAR
          </Typography>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ pt: 10 }}
        >
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() =>
                navigate("../pesquisa-consulta", {
                  replace: true,
                  state: { patientId: patientId },
                })
              }
            >
              PULAR
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => capture()}
            >
              SIM
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PreCaptureComponent;
