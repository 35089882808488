import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Profile from "../Profile";
import Consultation from "../Consultation";

const WithAppointment: React.FC<any> = ({
  name,
  cpf,
  date,
  time,
  doctor,
  doctorArea,
  appointmentId,
  patientId,
  doctorId
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        sx={{ pl: 3, pr: 3, pt: 10, pb: 5 }}
      >
        <Grid item sx={{ pb: 10 }}>
          <Typography variant="h3">
          Localizamos seus dados. Você confirma a consulta?
          </Typography>
        </Grid>
        <Profile name={name} cpf={cpf} />
        <Consultation date={date} time={time} doctor={doctor} doctorArea={doctorArea} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ pt: 10 }}
        >
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("../captura")}
            >
              NÃO
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() =>
                navigate("../confirmacao-feedback", {
                  replace: true,
                  state: {
                    appointmentId: appointmentId,
                    patientId: patientId,
                    doctorId: doctorId,
                    appointmentTime: time
                  },
                })
              }
            > 
              SIM
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default WithAppointment;
