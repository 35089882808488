import React, { useState, useEffect } from "react";
import ChooseDoctorComponent from "../../components/ChooseDoctor";
import ExternalError from "../../components/ExternalError";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../../services/api";
import Loading from "../../components/Loading";

const ChooseDoctor: React.FC = () => {
  type LocationState = {
    name: string;
    cpf: string;
    patientId: string;
  };

  const location = useLocation();
  const { patientId, name, cpf } = location.state as LocationState;
  const d = new Date();
  const [loading, setLoading] = useState<boolean>(true);
  const [doctorNames, setDoctorNames] = useState<any>([]);
  const [doctorOne, setDoctorOne] = useState<number>(0);
  const [doctorTwo, setDoctorTwo] = useState<number>(1);
  const [doctorThree, setDoctorThree] = useState<number>(2);
  const [isDisabledMinus, setIsDisabledMinus] = useState<boolean>(true);
  const [isDisabledPlus, setIsDisabledPlus] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [doctorsToDivide, setDoctorsToDivide] = useState<any>();
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  var date;

  const changeDoctorMinus = () => {
    setDoctorOne(doctorOne - 3);
    setDoctorTwo(doctorTwo - 3);
    setDoctorThree(doctorThree - 3);
    setCounter(counter - 1);
    if (counter == 1) {
      setIsDisabledMinus(true);
    } else {
      setIsDisabledMinus(false);
    }
    setIsDisabledPlus(false);
  };

  const changeDoctorPlus = () => {
    setDoctorOne(doctorOne + 3);
    setDoctorTwo(doctorTwo + 3);
    setDoctorThree(doctorThree + 3);
    setCounter(counter + 1);
    if (counter == doctorsToDivide - 2) {
      setIsDisabledPlus(true);
    } else {
      setIsDisabledPlus(false);
    }
    setIsDisabledMinus(false);
  };

  const showDoctorName = (doctorName: any[]) => {
    if (
      doctorName == undefined ||
      !doctorName[0].nomeProfissional ||
      doctorName[0].nomeProfissional == null
    ) {
      return "----";
    } else {
      return doctorName[0].nomeProfissional;
    }
  };

  const setIsDisabled = (doctorName: any) => {
    if (!doctorName || doctorName == undefined || doctorName == null) {
      return true;
    } else {
      return false;
    }
  };

  const setDateBegin = () => {
    if (d.getDate() <= 9) {
      date = "0" + d.getDate();
    } else {
      date = d.getDate();
    }
    if (d.getMonth() + 1 <= 9) {
      getDoctorNames(d.getFullYear() + "-0" + (d.getMonth() + 1) + "-" + date);
    } else {
      getDoctorNames(d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + date);
    }
  };

  useEffect(() => {
    setDateBegin();
  }, []);

  const getDoctorNames = async (date: string) => {
    const doctorNamesReq = await api.post("appointment/doctor-list", {
      date: date,
      capsuleKey: localStorage.getItem("capsuleKey"),
    });
    if (doctorNamesReq.data === "Error") {
      setError(true);
    }
    // setDoctorNames([doctorNamesReq.data[0][0], doctorNamesReq.data[1][0], doctorNamesReq.data[2][0]]);
    setDoctorNames(doctorNamesReq.data);
    setDoctorsToDivide((doctorNamesReq.data.length / 3).toFixed(0));
    setLoading(false);
  };

  const selectedDoctor = async (doctorId, doctorName, doctorAgenda) => {
    navigate("../escolher-data", {
      replace: true,
      state: {
        doctorId: doctorId,
        name: name,
        patientId: patientId,
        cpf: cpf,
        doctorName: doctorName,
        doctorAgenda: doctorAgenda,
      },
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <ExternalError />
      ) : (
        <ChooseDoctorComponent
          doctorNames={doctorNames}
          selectedDoctor={selectedDoctor}
          doctorOne={doctorOne}
          doctorTwo={doctorTwo}
          doctorThree={doctorThree}
          isDisabledMinus={isDisabledMinus}
          isDisabledPlus={isDisabledPlus}
          changeDoctorMinus={changeDoctorMinus}
          changeDoctorPlus={changeDoctorPlus}
          showDoctorName={showDoctorName}
          setIsDisabled={setIsDisabled}
        />
      )}
    </>
  );
};
export default ChooseDoctor;
