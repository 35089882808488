import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";

const ChooseCapsule: React.FC = () => {
  const navigate = useNavigate();

  const setCapsuleKey = (key: string) => {
    localStorage.setItem("capsuleKey", key);
    navigate("../inicio", { replace: true });
  };

  return (
    <div className="page-feedback">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="column"
        sx={{ pl: 5, pr: 5, pt: 30, pb: 5 }}
      >
        <Grid item xs={12}>
          <Typography variant="h3">Selecione a cápsula:</Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 10 }}
          spacing={3}
        >
          <Grid item xs={12}>
            <Grid item xs={12} sx={{ pb: 1 }}>
              <Button
                variant="text"
                size="large"
                fullWidth
                style={{ height: 50 }}
                sx={{ boxShadow: 2 }}
                onClick={() => {
                  setCapsuleKey("doc1");
                }}
              >
                <Typography style={{ color: "black" }}>
                  doc1 - CEMIG Belo Horizonte
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Button
              variant="text"
              size="large"
              fullWidth
              style={{ height: 50 }}
              sx={{ boxShadow: 2 }}
              onClick={() => {
                setCapsuleKey("doc2");
              }}
            >
              <Typography style={{ color: "black" }}>
                doc2 - UISA
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChooseCapsule;
