import React from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import Free from "../../images/Free.png";

const FreeStatus: React.FC = () => {
  const navigate = useNavigate();
  const redirectToCapture = async () => {
    navigate("../captura", { replace: true });
  };

  return (
    <>
      <Grid container direction="column" sx={{ pl: 3, pr: 3, pt: 3 }}>
        <Grid item>
          <Typography variant="h1">Status da Cápsula</Typography>
        </Grid>
        <Grid container alignItems="center">
          <Grid item sx={{ pr: 0.5, pt: 2 }}>
            <CheckCircleIcon className="image-free" fontSize="large" />
          </Grid>
          <Grid item sx={{ pt: 1.2 }}>
            <Typography variant="h4">Livre</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="column"
        sx={{ pl: 10, pr: 10, pt: 10, pb: 10 }}
      >
        <Grid item sx={{ pb: 5 }}>
          <img src={Free} className="photo-free" />
        </Grid>
        <Grid item>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Olá! Boas Vindas!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2" sx={{ pb: 2 }}>
            Nossa Missão aqui é facilitar ao máximo o seu acesso à saúde.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">Então vamos lá!</Typography>
        </Grid>

        <Grid item sx={{ pt: 7 }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => redirectToCapture()}
            style={{ borderRadius: 10, width: 250, height: 60 }}
          >
            Iniciar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default FreeStatus;
