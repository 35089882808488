import React, { useState, useRef, useCallback, useEffect } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import CaptureComponent from "../../components/Capture";
import Webcam from "react-webcam";
import { api } from "../../services/api";
import CaptureLoading from "../../components/CaptureLoading";

const Capture: React.FC = () => {
  const navigate = useNavigate();
  const webcamRef = useRef<Webcam | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string | null>(null);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }
    setLoading(true);
  }, [webcamRef, setImgSrc]);

  const sendImage = async () => {
    const facialRecognitionRequest = { image: imgSrc, idEmpresa: "3" };

    const { data: facialRecognitionData } = await api.post(
      "facial-recognition",
      facialRecognitionRequest
    );

    if (!facialRecognitionData?.data || !facialRecognitionData?.data[0]?.user) {
      navigate("../consulta-paciente", { replace: true });
    } else {
      navigate("../pesquisa-consulta", {
        replace: true,
        state: {
          patientId: facialRecognitionData.data[0].user.user_id,
        },
      });
    }
  };

  useEffect(() => {
    sendImage();
  }, [imgSrc]);

  return (
    <div className="page-capture">
      {loading ? (
        <CaptureLoading />
      ) : (
        <CaptureComponent
          capture={capture}
          webcamRef={webcamRef}
          navigate={navigate}
        />
      )}
    </div>
  );
};

export default Capture;
