import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Consultation from "../../components/Consultation";
import Profile from "../../components/Profile";

const NewAppointmentConfirmation: React.FC = () => {
  type LocationState = {
    doctorId: string;
    doctorName: string;
    doctorAgenda: any;
    date: string;
    dateMin: string;
    doctorHours: any;
    name: string;
    cpf: string;
    hourBegin: string;
    hourEnd: string;
    patientId: string;
    appointmentTypeId: string;
    localId: string;
  };
  const navigate = useNavigate();
  const location = useLocation();
  const {
    doctorId,
    doctorName,
    doctorAgenda,
    date,
    dateMin,
    doctorHours,
    name,
    cpf,
    hourBegin,
    hourEnd,
    patientId,
    appointmentTypeId,
    localId,
  } = location.state as LocationState;
  
  const scheduleAppointment = () => {
    const appointmentId = api.post("appointment/schedule-appointment", {
      date: date,
      hourEnd: hourEnd,
      hourBegin: hourBegin,
      patientId: patientId,
      localId: localId,
      doctorId: doctorId,
      appointmentTypeId: appointmentTypeId,
    });

    navigate("../confirmacao-nova-consulta", { replace: true });
  };

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    let newDate = `${day}/${month}/${year}`;
    return newDate;
  };

  return (
    <>
      <Grid item xs={12} sx={{ pl: 3, pt: 3 }}>
        <Button
          onClick={() =>
            navigate("../escolher-hora", {
              state: {
                doctorId: doctorId,
                dateMin: dateMin,
                doctorHours: doctorHours,
                name: name,
                cpf: cpf,
                patientId: patientId,
                doctorName: doctorName,
                doctorAgenda: doctorAgenda,
              },
            })
          }
        >
          <Grid item sx={{ pr: 0.7, pt: 1 }}>
            <ArrowBackIcon fontSize="small" />
          </Grid>
          <Grid item sx={{ pt: 0.2 }}>
            <p>VOLTAR</p>
          </Grid>
        </Button>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        sx={{ pl: 5, pr: 5, pt: 10, pb: 5 }}
      >
        <Grid item sx={{ pb: 10, pl: 3, pr: 3 }}>
          <Typography variant="h3">
            Verifique seus dados. Se estiverem corretos, clique em "Confirmar
            consulta".{" "}
          </Typography>
        </Grid>
        <Profile name={name} cpf={cpf} />
        <Consultation
          date={formatDate(date)}
          time={hourBegin}
          doctor={doctorName}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ pt: 10 }}
        >
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("../inicio", { replace: true })}
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => scheduleAppointment()}
            >
              CONFIRMAR CONSULTA
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NewAppointmentConfirmation;
