import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";

const WithoutId: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        spacing={2}
        sx={{ pt: 20, pb: 5, pr: 5, pl: 5 }}
      >

        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant="h1">
            Ops... Encontramos uma falha cadastral.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">
            Atualize seus dados com seu profissoional de referência, para
            podermos permitir seu agendamento de consultas.
          </Typography>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="right"
          sx={{ pt: 20 }}
        >
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              style={{ borderRadius: 10 }}
              onClick={() => navigate("../inicio", { replace: true })}
            >
              VOLTAR AO INÍCIO
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
};
export default WithoutId;
