import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Finish from "../../images/Finish.png";

const NewAppointmentFeedback: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      navigate("../inicio", { replace: true });
    }, 20 * 1000);
  }, []);

  return (
    <div className="page-feedback">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="column"
        sx={{ pl: 5, pr: 5, pt: 30, pb: 5 }}
      >
        <Grid item sx={{ pb: 10 }}>
          <img src={Finish} style={{ height: "280px" }} />
        </Grid>
        <Grid item>
          <Typography variant="h3">
            Prontinho! Sua consulta foi confirmada!
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewAppointmentFeedback;
