import React, { useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { IconButton, OutlinedInput, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackspaceIcon from "@mui/icons-material/Backspace";
import InputAdornment from '@mui/material/InputAdornment';

const PatientAppointment: React.FC = () => {
  const [idValue, setIdValue] = useState<string>("");

  const setId = (value) => {
    setIdValue(idValue + value);
  };

  const removeId = () => {
    setIdValue(idValue.slice(0, -1));
  };

  const sendIdPatient = async () => {
    navigate("../confirmacao-registro", {
      replace: true,
      state: {
        patientId: idValue,
      },
    });
  };

  const navigate = useNavigate();
  return (
    <div className="page-patient">
      <Grid container sx={{ pl: 3, pr: 3, pt: 3 }}>
        <Button onClick={() => navigate("../captura", { replace: true })}>
          <Grid item sx={{ pr: 0.7, pt: 1 }}>
            <ArrowBackIcon fontSize="small" />
          </Grid>
          <Grid item sx={{ pt: 0.2 }}>
            <p>VOLTAR</p>
          </Grid>
        </Button>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="column"
        sx={{ pl: 5, pr: 5, pt: 8, pb: 10 }}
      >
        <Grid item sx={{ pb: 6, pl: 3, pr: 3 }}>
          <Typography variant="h3">
            Digite o número da sua carteirinha ou CPF:
          </Typography>
        </Grid>
        <Grid item sx={{ pb: 5 }}>
          <OutlinedInput value={idValue} style={{ width: 360 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton>
                  <BackspaceIcon
                    onClick={() => removeId()}
                    style={{ color: "black" }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid container direction="column" spacing={1} sx={{ pb: 15 }}>
          <Grid item xs={2} spacing={2}>
            <Button
              onClick={() => setId(1)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>1</Typography>
            </Button>
            <Button
              onClick={() => setId(2)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>2</Typography>
            </Button>
            <Button
              onClick={() => setId(3)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>3</Typography>
            </Button>
          </Grid>
          <Grid item xs={2} spacing={2}>
            <Button
              onClick={() => setId(4)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>4</Typography>
            </Button>
            <Button
              onClick={() => setId(5)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>5</Typography>
            </Button>
            <Button
              onClick={() => setId(6)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>6</Typography>
            </Button>
          </Grid>
          <Grid item xs={2} spacing={2}>
            <Button
              onClick={() => setId(7)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>7</Typography>
            </Button>
            <Button
              onClick={() => setId(8)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>8</Typography>
            </Button>
            <Button
              onClick={() => setId(9)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>9</Typography>
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={() => setId(0)}
              variant="text"
              style={{ width: 120, height: 60 }}
              sx={{ boxShadow: 2 }}
            >
              <Typography variant="h3" style={{ color: "black" }}>0</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("../captura", { replace: true })}
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => sendIdPatient()}
            >
              ENVIAR
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientAppointment;
