import axios from "axios";
import qs from "qs";

export const api = axios.create({
  baseURL: process.env.API_URL || "https://capsula-api.dixhealth.com.br/api",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});
