import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import RegisterIcon from "../../images/NotRegister.png";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Profile from "../../components/Profile";

const ExpiredId: React.FC<any> = ({ name, cpf }) => {
  const navigate = useNavigate();
  return (
    <>
      <Grid container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        sx={{ pl: 3, pr: 3, pt: 10, pb: 5 }}>

        <Profile name={name} cpf={cpf} />

        <Grid item xs={12} sx={{ pt: 5 }}>
          <img src={RegisterIcon} style={{ height: "320px", width: "330px" }} />
        </Grid>
        <Grid item xs={12} sx={{ pt: 5 }}>
          <Typography variant="h3">Ops... encontramos uma falha cadastral</Typography>
        </Grid>
        <Grid item xs={12} sx={{ pt: 2 }}>
          <Typography variant="h2">Atualize seus dados com seu profissional de referência, assim poderemos permitir seu agendamento de consultas</Typography>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{ pt: 5 }}
        >
          <Grid item xs={6}>
            <Button variant="contained" color="primary" size="large" style={{ borderRadius: 10 }} fullWidth onClick={() => navigate('/inicio')}>
              VOLTAR AO INÍCIO
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ExpiredId;
