import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CarouselData from "../../components/CarouselData";
import Hours from "../../components/Hours";

const ChooseHour: React.FC = () => {
  type LocationState = {
    doctorId: string;
    dateMin: string;
    doctorHours: any;
    name: string;
    cpf: string;
    doctorAgenda: any;
    patientId: string;
    doctorName: string;
  };

  const navigate = useNavigate();
  const [hourOne, setHourOne] = useState<number>(0);
  const [hourTwo, setHourTwo] = useState<any>(1);
  const [hourThree, setHourThree] = useState<any>(2);
  const [hourFour, setHourFour] = useState<any>(3);
  const [hourFive, setHourFive] = useState<any>(4);
  const [hourSix, setHourSix] = useState<any>(5);
  const [hourSeven, setHourSeven] = useState<any>(6);
  const [hourEight, setHourEight] = useState<any>(7);
  const [isDisabledMinus, setIsDisabledMinus] = useState<boolean>(true);
  const [isDisabledPlus, setIsDisabledPlus] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const location = useLocation();
  const {
    doctorId,
    dateMin,
    doctorHours,
    name,
    cpf,
    patientId,
    doctorName,
    doctorAgenda,
  } = location.state as LocationState;
  const [hoursToDivide, setHoursToDivide] = useState<any>(
    (parseInt(doctorHours.horarios.length) / 8).toFixed(0)
  );

  const changeHourMinus = () => {
    setHourOne(hourOne - 8);
    setHourTwo(hourTwo - 8);
    setHourThree(hourThree - 8);
    setHourFour(hourFour - 8);
    setHourFive(hourFive - 8);
    setHourSix(hourSix - 8);
    setHourSeven(hourSeven - 8);
    setHourEight(hourEight - 8);
    if (counter == 0) {
      setIsDisabledMinus(true);
    } else {
      setCounter(counter - 1);
      setIsDisabledMinus(false);
    }
    setIsDisabledPlus(false);
  };

  const changeHourPlus = () => {
    setHourOne(hourOne + 8);
    setHourTwo(hourTwo + 8);
    setHourThree(hourThree + 8);
    setHourFour(hourFour + 8);
    setHourFive(hourFive + 8);
    setHourSix(hourSix + 8);
    setHourSeven(hourSeven + 8);
    setHourEight(hourEight + 8);
    if (counter == hoursToDivide - 1 || counter == hoursToDivide) {
      setIsDisabledPlus(true);
    } else {
      setCounter(counter + 1);
      setIsDisabledPlus(false);
    }
    setIsDisabledMinus(false);
  };

  const setHours = (arrayPos) => {
  if (
      !doctorHours.horarios[arrayPos] ||
      doctorHours.horarios[arrayPos] == undefined ||
      doctorHours.horarios[arrayPos] == null
    ) {
      return "---"
    } else {
      return doctorHours.horarios[arrayPos].horaInicio;
    }
  };

  const setIsDisabled = (arrayPos) => {
    if (
      !doctorHours.horarios[arrayPos] ||
      doctorHours.horarios[arrayPos] == undefined ||
      doctorHours.horarios[arrayPos] == null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    let newDate = `${day}/${month}/${year}`;
    return newDate;
  };

  const setSelectedHour = (arrayPos) => {
    navigate("../novo-agendamento", {
      replace: true,
      state: {
        doctorId: doctorId,
        date: dateMin,
        dateMin: dateMin,
        doctorHours: doctorHours,
        hourBegin: doctorHours.horarios[arrayPos].horaInicio,
        hourEnd: doctorHours.horarios[arrayPos].horaFim,
        localId: doctorHours.horarios[arrayPos].idLocal,
        appointmentTypeId: doctorHours.horarios[arrayPos].idTipoConsulta,
        name: name,
        cpf: cpf,
        patientId: patientId,
        doctorName: doctorName,
        doctorAgenda: doctorAgenda,
      },
    });
  };

  return (
    <>
      <Grid
        display="flex"
        container
        direction="row"
        alignItems="center"
        sx={{ pt: 3, pl: 3, pr: 3, pb: 5 }}
      >
        <Grid container>
          <Button
            onClick={() =>
              navigate("../escolher-data", {
                state: {
                  doctorId: doctorId,
                  name: name,
                  patientId: patientId,
                  cpf: cpf,
                  doctorName: doctorName,
                  doctorAgenda: doctorAgenda,
                },
              })
            }
          >
            <Grid item sx={{ pr: 0.7, pt: 1 }}>
              <ArrowBackIcon fontSize="small" />
            </Grid>
            <Grid item sx={{ pt: 0.2 }}>
              <p>VOLTAR</p>
            </Grid>
          </Button>
        </Grid>
        <Grid
          display="flex"
          container
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          direction="row"
          sx={{ pl: 2, pr: 2, pt: 5, pb: 5 }}
        >
          {/*<CarouselData style={{ color: "grey" }} />*/}
          <Hours
            doctorHours={doctorHours}
            setSelectedHour={setSelectedHour}
            doctorName={doctorName}
            date={dateMin}
            formatDate={formatDate}
            hourOne={hourOne}
            hourTwo={hourTwo}
            hourThree={hourThree}
            hourFour={hourFour}
            hourFive={hourFive}
            hourSix={hourFive}
            hourSeven={hourFive}
            hourEight={hourFive}
            isDisabledMinus={isDisabledMinus}
            isDisabledPlus={isDisabledPlus}
            changeHourMinus={changeHourMinus}
            changeHourPlus={changeHourPlus}
            setHours={setHours}
            setIsDisabled={setIsDisabled}
          />

          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            sx={{ pt: 15 }}
          >
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                style={{ borderRadius: 10 }}
                fullWidth 
                onClick={() => navigate("/inicio")}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ChooseHour;
