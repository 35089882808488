import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Profile from "../Profile";
import Consultation from "../Consultation";

const AppointmentTooLate: React.FC<any> = ({
  name,
  cpf,
  date,
  time,
  doctor,
  doctorArea,
  patientId,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        sx={{ pl: 5, pr: 5, pt: 10, pb: 5 }}
      >
        <Grid item sx={{ pb: 10 }}>
          <Typography variant="h3">
            Você atrasou mais que 10 minutos, sua consulta foi dada como falta!
            <br />
            Clique em "REAGENDAR" para marcar uma nova consulta.
          </Typography>
        </Grid>
        <Profile name={name} cpf={cpf} />
        <Consultation
          date={date}
          time={time}
          doctor={doctor}
          doctorArea={doctorArea}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ pt: 10 }}
        >
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("../inicio", { replace: true })}
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() =>
                navigate("../escolher-medico", {
                  replace: true,
                  state: { patientId: patientId, name: name, cpf: cpf },
                })
              }
            >
              REAGENDAR CONSULTA
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default AppointmentTooLate;
