import React, { useState, useEffect } from "react";
import "./styles.css";
import { useLocation } from "react-router-dom";
import { api } from "../../services/api";
import ExternalError from "../../components/ExternalError";
import Loading from "../../components/Loading";
import WithAppointment from "../../components/WithAppointment";
import WithoutAppointment from "../../components/WithoutAppointment";
import AppointmentTooSoon from "../../components/AppointmentTooSoon";
import AppointmentTooLate from "../../components/AppointmentTooLate";
import ExpiredId from "../../components/ExpiredId";
import WithoutId from "../../components/WithoutId";

const AppointmentSearch: React.FC = () => {
  type LocationState = {
    patientId: {
      patientId: string;
    };
  };

  const [findingAppointment, setFindingAppointment] =
    useState<string>("searching");
  const [isExpiredId, setIsExpiredId] = useState<boolean>(false);
  const [namePatient, setNamePatient] = useState<string>();
  const [cpfPatient, setCpfPatient] = useState<string>();
  const [appointmentDate, setAppointmentDate] = useState<string>();
  const [appointmentRawDate, setAppointmentRawDate] = useState<string>();
  const [appointmentTime, setAppointmentTime] = useState<string>();
  const [appointmentDoctor, setAppointmentDoctor] = useState<string>();
  const [doctorArea, setDoctorArea] = useState<string>();
  const [doctorId, setDoctorId] = useState<string>();
  const [appointmentId, setAppointmentId] = useState<string>();
  const location = useLocation();
  const { patientId } = location.state as LocationState;
  const d = new Date();

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    let newDate = `${day}/${month}/${year}`;
    return newDate;
  };

  const checkExpiredId = (currentDate, idDate) => {
    if (currentDate > idDate) {
      setIsExpiredId(true);
    } else if (currentDate > idDate && isExpiredId !== true) {
      setIsExpiredId(false);
    }
  };

  const checkAppointmentTime = (appointmentTime, dateStr) => {
    const [year, month, day] = dateStr.split("-");
    const appointmentHour = appointmentTime.slice(0, 2);
    const appointmentMinutes = appointmentTime.slice(-2);

    const appointmentDateTime = Date.parse(
      `${year}-${month}-${day}T${appointmentHour}:${appointmentMinutes}:00.000Z`
    );
    const currentTime = d.getTime();
    var minutes;

    if (localStorage.getItem("capsuleKey") === "doc2") {
      minutes = Math.floor((currentTime - appointmentDateTime) / 60000 - 240);
    } else {
      minutes = Math.floor((currentTime - appointmentDateTime) / 60000 - 180);
    }

    console.log(minutes)

    if (minutes > 10) {
      setFindingAppointment("tooLate");
    } else if (minutes < -5) {
      setFindingAppointment("tooSoon");
    } else {
      setFindingAppointment("hasAppointment");
    }
  };

  const findAppointment = async () => {
    const appointmentRequest = { patientId: patientId };
    const patientRequest = { id: patientId };

    const { data: patientData } = await api.get("patient", {
      params: patientRequest,
    });

    const { data: appointmentData } = await api.get("appointment", {
      params: appointmentRequest,
    });

    if (patientData == 'Error' || appointmentData == 'Error') {
      setFindingAppointment('error');
    }

    if (patientData.content[0]) {
      for (let i = 0; i <= patientData.content[0].carteirinhas.lenght; i++) {
        checkExpiredId(
          d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate(),
          patientData.content[0].carteirinhas[i].validade
        );
      }

      setNamePatient(patientData.content[0].nome);
      setCpfPatient(patientData.content[0].documentos[0].documento);

      if (appointmentData[0]) {
        checkAppointmentTime(
          appointmentData[0].horaInicio,
          appointmentData[0].data
        );
        setAppointmentId(appointmentData[0].idConsulta);
		setAppointmentRawDate(appointmentData[0].data);
        setAppointmentDate(formatDate(appointmentData[0].data));
        setAppointmentTime(appointmentData[0].horaInicio);
        setAppointmentDoctor(appointmentData[0].nomeProfissional);
        setDoctorArea(appointmentData[1]);
        setDoctorId(appointmentData[0].idProfissional);
      } else {
        setFindingAppointment("dontHaveAppointment");
      }
    } else {
      setFindingAppointment("dontHaveId");
    }
  };

  useEffect(() => {
    findAppointment();
  }, []);

  return (
    <div className="page-appointment">
      {findingAppointment === "searching" ? (
        <Loading /> 
      ): isExpiredId ? (
        <ExpiredId name={namePatient} cpf={cpfPatient} />
      ) : findingAppointment === "hasAppointment" ? (
        <WithAppointment
          name={namePatient}
          cpf={cpfPatient}
          date={appointmentDate}
          time={appointmentTime}
          doctor={appointmentDoctor}
          doctorArea={doctorArea}
          appointmentId={appointmentId}
          patientId={patientId}
          doctorId={doctorId}
        />
      ) : findingAppointment === "error" ? (
        <ExternalError />
      ) : findingAppointment === "dontHaveId" ? (
        <WithoutId />
      ) : findingAppointment === "tooSoon" ? (
        <AppointmentTooSoon
          name={namePatient}
          cpf={cpfPatient}
          date={appointmentDate}
		  rawDate={appointmentRawDate}
          time={appointmentTime}
          doctor={appointmentDoctor}
          doctorArea={doctorArea}
        />
      ) : findingAppointment === "tooLate" ? (
        <AppointmentTooLate
          name={namePatient}
          cpf={cpfPatient}
          date={appointmentDate}
          time={appointmentTime}
          doctor={appointmentDoctor}
          doctorArea={doctorArea}
          patientId={patientId}
        />
      ) : (
        <WithoutAppointment
          name={namePatient}
          cpf={cpfPatient}
          patientId={patientId}
        />
      )}
    </div>
  );
};

export default AppointmentSearch;