import React from "react";
import "./styles.css";
import Grid from "@mui/material/Grid";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Typography from "@mui/material/Typography";
import Waiting from "../../images/Waiting.png";

const WaitingStatus: React.FC = () => {
  return (
    <>
      <Grid container direction="column" sx={{ pl: 3, pr: 3, pt: 3 }}>
        <Grid item>
          <Typography variant="h1">Status da Cápsula</Typography>
        </Grid>
        <Grid container alignItems="center">
          <Grid item sx={{ pr: 0.5, pt: 1 }}>
            <ErrorRoundedIcon className="image-waiting" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h4">Em preparação</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        textAlign="center"
        direction="column"
        sx={{ pl: 10, pr: 10, pt: 10, pb: 10 }}
      >
        <Grid item sx={{ pb: 7 }}>
          <img src={Waiting} className="photo-waiting" />
        </Grid>
        <Grid item>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Olá!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2" sx={{ pb: 2 }}>
            Estamos higienizando a Cápsula para atender você com toda a
            segurança possível.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">Aguarde só um instante.</Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default WaitingStatus;