import React from "react";
import { Grid, Button, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Hours: React.FC<any> = ({
  doctorHours,
  setSelectedHour,
  doctorName,
  date,
  formatDate,
  hourOne,
  hourTwo,
  hourThree,
  hourFour,
  hourFive,
  hourSix,
  hourSeven,
  hourEight,
  isDisabledMinus,
  isDisabledPlus,
  changeHourMinus,
  changeHourPlus,
  setHours,
  setIsDisabled,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={7.5} textAlign="left">
          <Typography variant="h1" sx={{ pb: 0.8 }}>
            Profissional da saúde
          </Typography>
        </Grid>
        <Grid item xs={4.5} textAlign="left">
          <Typography variant="h1" sx={{ pb: 0.8 }}>
            Data da Consulta
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={7.5}
          textAlign="left"
          className="infoProfile"
        >
          <Typography variant="h1" sx={{ pl: 1.5, pt: 1.5, pb: 1.5 }}>
            {doctorName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4.5}
          textAlign="left"
          className="infoProfile"
        >
          <Typography variant="h1" sx={{ pl: 1.5, pt: 1.5, pb: 1.5 }}>
            {formatDate(date)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ pt: 10 }}>
        <Typography variant="h1">Horário</Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="left"
        sx={{ pt: 5 }}
        xs={12}
      >
        <Grid container justifyContent="left" alignItems="center" xs={1.5}>
          <Button onClick={() => changeHourMinus()} disabled={isDisabledMinus}>
            <ArrowBackIosNewIcon style={{ color: "black" }} />
          </Button>
        </Grid>
        <Grid container spacing={3} xs={9}>
          <Grid item xs={3}>
            <Button
              variant="text"
              disabled={setIsDisabled(hourOne)}
              onClick={() => setSelectedHour(hourOne)}
              style={{ backgroundColor: "#f0f3f4", width: 90, height: 50 }}
            >
              <Typography style={{ color: "black" }}>
                {setHours(hourOne)}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="text"
              disabled={setIsDisabled(hourTwo)}
              onClick={() => setSelectedHour(hourTwo)}
              style={{ backgroundColor: "#f0f3f4", width: 90, height: 50 }}
            >
              <Typography style={{ color: "black" }}>
                {setHours(hourTwo)}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="text"
              disabled={setIsDisabled(hourThree)}
              onClick={() => setSelectedHour(hourThree)}
              style={{ backgroundColor: "#f0f3f4", width: 90, height: 50 }}
            >
              <Typography style={{ color: "black" }}>
                {setHours(hourThree)}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="text"
              disabled={setIsDisabled(hourFour)}
              onClick={() => setSelectedHour(hourFour)}
              style={{ backgroundColor: "#f0f3f4", width: 90, height: 50 }}
            >
              <Typography style={{ color: "black" }}>
                {setHours(hourFour)}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="text"
              disabled={setIsDisabled(hourFive)}
              onClick={() => setSelectedHour(hourFive)}
              style={{ backgroundColor: "#f0f3f4", width: 90, height: 50 }}
            >
              <Typography style={{ color: "black" }}>
                {setHours(hourFive)}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="text"
              disabled={setIsDisabled(hourSix)}
              onClick={() => setSelectedHour(hourSix)}
              style={{ backgroundColor: "#f0f3f4", width: 90, height: 50 }}
            >
              <Typography style={{ color: "black" }}>
                {setHours(hourSix)}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="text"
              disabled={setIsDisabled(hourSeven)}
              onClick={() => setSelectedHour(hourSeven)}
              style={{ backgroundColor: "#f0f3f4", width: 90, height: 50 }}
            >
              <Typography style={{ color: "black" }}>
                {setHours(hourSeven)}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="text"
              disabled={setIsDisabled(hourEight)}
              onClick={() => setSelectedHour(hourEight)}
              style={{ backgroundColor: "#f0f3f4", width: 90, height: 50 }}
            >
              <Typography style={{ color: "black" }}>
                {setHours(hourEight)}
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="right" alignItems="center" xs={1.5}>
          <Button onClick={() => changeHourPlus()} disabled={isDisabledPlus}>
            <ArrowForwardIosIcon style={{ color: "black" }} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default Hours;
