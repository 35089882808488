import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const ExternalError: React.FC = () => {
  const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
           navigate("../inicio", {replace: true}) 
        }, 30 * 1000);
    }, [])

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        spacing={2}
        sx={{ pt: 20, pb: 5, pr: 5, pl: 5 }}
      >
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant="h1">
            Problema ao acessar base de dados!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">
            Por favor, tente novamente.
            Se o problema persistir,  procure sua enfermeira de emergência.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default ExternalError;
