import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Typography } from "@mui/material";

const HelpPage: React.FC = () => {
  const capsuleKey = localStorage.getItem("capsuleKey");
  const navigate = useNavigate();
  const [isTurnedOn, setIsTurnedOn] = useState < boolean > (false);
  const [buttonText, setButtonText] = useState < string > ("Ligar");

  const handleClick = () => {
    if (isTurnedOn) {
      api.post("automation/lights-off", {
        capsuleUrl: localStorage.getItem("capsuleUrl"),
      });
      setIsTurnedOn(false);
      setButtonText("Ligar");
    } else {
      api.post("automation/lights-on", {
        capsuleUrl: localStorage.getItem("capsuleUrl"),
      });
      setIsTurnedOn(true);
      setButtonText("Desligar");
    }
  };

  const openDoor = () => {
    api.post("automation/open-door", {
      capsuleUrl: localStorage.getItem("capsuleUrl"),
    });
  };

  const finishAppointment = async () => {
    await api.post(
      "https://capsula-api.dixhealth.com.br/api/appointment/finish-event",
      {
        totemId: `${capsuleKey}`,
      }
    );
  };

  return (
    <div className="page-feedback">
      <Grid container sx={{ pl: 3, pr: 3, pt: 3 }}>
        <Button onClick={() => navigate("../inicio", { replace: true })}>
          <Grid item sx={{ pr: 0.7, pt: 1 }}>
            <ArrowBackIcon fontSize="small" />
          </Grid>
          <Grid item sx={{ pt: 0.2 }}>
            <p>VOLTAR</p>
          </Grid>
        </Button>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="column"
        sx={{ pl: 5, pr: 5, pt: 30, pb: 5 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 10 }}
          spacing={3}
        >
          <Grid item xs={12}>
            <Grid item xs={12} sx={{ pb: 1 }}>
              <Button
                variant="text"
                size="large"
                fullWidth
                style={{ height: 50 }}
                sx={{ boxShadow: 2 }}
                onClick={() => {
                  handleClick();
                }}
              >
                <Typography style={{ color: "black" }}>
                  {buttonText} Luz da Cápsula
                </Typography>
              </Button>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Grid item xs={12} sx={{ pb: 1 }}>
              <Button
                variant="text"
                size="large"
                fullWidth
                style={{ height: 50 }}
                sx={{ boxShadow: 2 }}
                onClick={() => {
                  openDoor();
                }}
              >
                <Typography style={{ color: "black" }}>
                  Abrir Porta da Cápsula
                </Typography>
              </Button>
            </Grid>
          </Grid> */}
          <Grid item xs={12} sx={{ pb: 1 }}>
            <Button
              variant="text"
              size="large"
              fullWidth
              style={{ height: 50 }}
              sx={{ boxShadow: 2 }}
              onClick={() => {
                finishAppointment();
              }}
            >
              <Typography style={{ color: "black" }}>
                Encerra consulta
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HelpPage;
