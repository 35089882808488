import React from "react";
import { Grid, Button, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Card from "@mui/material/Card";

const CarouselData: React.FC<any> = ({
  setSelectedDoctorDates,
  setMonth,
  setDate,
  setIsDisabled,
  setDay,
  dateLeft,
  dateRight,
  dateMid,
  isDisabledMinus,
  isDisabledPlus,
  changeDataMinus,
  changeDataPlus,
  doctorName,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={7.5} textAlign="left">
          <Typography variant="h1" sx={{ pb: 0.8 }}>
            Profissional da saúde
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={7.5} textAlign="left" className="infoProfile">
          <Typography variant="h1" sx={{ pl: 1.5, pt: 1.5, pb: 1.5 }}>
            {doctorName}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ pt: 12 }}>
        <Typography variant="h1">Datas disponíveis</Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 8 }}
      >
        <Grid item textAlign="left" xs={1.5}>
          <Button onClick={() => changeDataMinus()} disabled={isDisabledMinus}>
            <ArrowBackIosNewIcon style={{ color: "black" }} />
          </Button>
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            direction="row"
            spacing={3}
          >
            <Grid item xs={4}>
              <Button
                disabled={setIsDisabled(dateLeft)}
                onClick={() => setSelectedDoctorDates(dateLeft)}
              >
                <Card style={{ width: "122px", height: "150px" }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: 2, pb: 2 }}
                  >
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "50px" }}>
                        {setDate(dateLeft)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "16px" }}>
                        {setMonth(dateLeft)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "16px" }}>
                        {setDay(dateLeft)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={setIsDisabled(dateMid)}
                onClick={() => setSelectedDoctorDates(dateMid)}
              >
                <Card style={{ width: "122px", height: "150px" }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: 2, pb: 2 }}
                  >
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "50px" }}>
                        {setDate(dateMid)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "16px" }}>
                        {setMonth(dateMid)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "16px" }}>
                        {setDay(dateMid)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={setIsDisabled(dateRight)}
                onClick={() => setSelectedDoctorDates(dateRight)}
              >
                <Card style={{ width: "122px", height: "150px" }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: 2, pb: 2 }}
                  >
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "50px" }}>
                        {setDate(dateRight)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "16px" }}>
                        {setMonth(dateRight)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "16px" }}>
                        {setDay(dateRight)}
                      </Typography>
                    </Grid> 
                  </Grid>
                </Card>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1.5}>
          <Button onClick={() => changeDataPlus()} disabled={isDisabledPlus}>
            <ArrowForwardIosIcon style={{ color: "black" }} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default CarouselData;