import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ConsultationIcon from "../../images/NotConsultation.png";
import Profile from "../Profile";

const WithoutAppointment: React.FC<any> = ({ name, cpf, patientId }) => {
  const navigate = useNavigate();
  return (
    <>
      <Grid container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        sx={{ pl: 5, pr: 5, pt: 10, pb: 5 }}>
        <Profile name={name} cpf={cpf} />
        <Grid container
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          direction="row"
          spacing={2}
          sx={{ pt: 10, pb: 10 }}>
          <Grid item xs={12}>
            <img src={ConsultationIcon} style={{ height: "300px", width: "390px" }} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">Não encontramos consulta agendada.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">Deseja agendar uma consulta?</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("../inicio", { replace: true })}
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary"
              size="large" fullWidth
              onClick={() => navigate('../escolher-medico',
                { replace: true, state: { patientId: patientId, name: name, cpf: cpf } })}
            >
              SIM
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default WithoutAppointment;
