import React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from "@mui/material";

const CaptureLoading: React.FC = () => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="column"
        sx={{ pl: 10, pr: 10, pt: 20, pb: 10 }}
      >
        <Grid item>
          <Typography variant="h3">
            Vamos verificar se você já é cadastrado
          </Typography>
        </Grid>
        <Grid item sx={{ pt: 10, pb: 10 }}>
          <CircularProgress style={{ width: 150, height: 150 }} />
        </Grid>
        <Grid item>
          <Typography variant="h1">
            Estamos buscando suas informações...
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
export default CaptureLoading;
