import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import Profile from "../../components/Profile";

const Scheduling: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        sx={{ pl: 3, pr: 3, pt: 20, pb: 5 }}
      >

        <Profile />

        <Grid container sx={{ pt: 10 }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">Não encontramos consulta agendada.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h1">Deseja agendar uma consulta?</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ pt: 20 }}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("../inicio", { replace: true })}
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("../inicio", { replace: true })}
            >
              SIM
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Scheduling;
