import React from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Typography from "@mui/material/Typography";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="header">
      <Grid container alignItems="center" display="flex">
        <Grid item xs={4} md={2} alignItems="center">
          <img alt="logo" className="logo" src={logo} />
        </Grid>
        <Grid item xs={8} md={10} textAlign="right">
          {/* <Button variant="outlined"
            style={{
              borderColor: "white", width: 10, height: 50,
              borderRadius: 12, borderWidth: 2, marginRight: 15
            }}
          >
            <VolumeUpIcon className="iconVolume" />
          </Button>*/}

          <Button
            onClick={() => navigate("../ajuda", { replace: true })}
            variant="contained"
            color="primary"
            size="large"
            style={{
              backgroundColor: "white",
              width: 120,
              height: 50,
              borderRadius: 12,
            }}
          >
            <Typography className="textHelp">Ajuda</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
