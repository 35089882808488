import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.css";
import { api } from "../../services/api";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Finish from "../../images/Finish.png"

const FeedbackConfirmation: React.FC = () => {
  type LocationState = {
    appointmentId: string;
    patientId: string;
    doctorId: string;
    appointmentTime: string; 
  };
  const locate = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = React.useState(10);
  const { appointmentId, patientId, doctorId, appointmentTime } = locate.state as LocationState;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const changeAppointmentStatus = async () => {
    const idAppointment = appointmentId;
    const appointmentRequest = {
      appointmentId: idAppointment,
      patientId: patientId,
      capsuleKey: localStorage.getItem('capsuleKey'),
      doctorId: doctorId,
      capsuleUrl: localStorage.getItem('capsuleUrl'),
      appointmentTime: appointmentTime
    };
    await api.post("appointment/confirmation", appointmentRequest);
  };

  React.useEffect(() => {
    changeAppointmentStatus();
    setTimeout(() => {
      navigate("../inicio", { replace: true });
    }, 60 * 1000);
  }, []);

  return (
    <div className="page-feedback">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="column"
        sx={{ pl: 5, pr: 5, pt: 20, pb: 5 }}
      >
        <Grid item sx={{ pb: 10 }}>
          <img src={Finish} style={{ height: "280px" }} />
        </Grid>
        <Grid item>
          <Typography variant="h3">
            Prontinho! Sua consulta foi confirmada!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2" sx={{ pt: 2 }}>
            Aguarde a liberação da porta.
          </Typography>
        </Grid>

      </Grid>
    </div>
  );
};

export default FeedbackConfirmation;
