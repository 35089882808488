import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Profile from "../Profile";
import Consultation from "../Consultation";

const AppointmentTooSoon: React.FC<any> = ({
  name,
  cpf,
  date,
  time,
  doctor,
  doctorArea,
  rawDate
}) => {
  const [appointmentSoonTime, setAppointmentSoonTime] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (time) {
		const ms = new Date(`${rawDate} ${time}`).getTime();
		if (localStorage.getItem("capsuleKey") === "doc2") {
			setAppointmentSoonTime(new Date(ms - 300000).toLocaleString('pt-br', {timeZone: 'America/Cuiaba'}).split(', ')[1].slice(0, 5))
		} else {
			setAppointmentSoonTime(new Date(ms - 300000).toLocaleString('pt-br', {timeZone: 'America/Sao_Paulo'}).split(', ')[1].slice(0, 5))
		}
    }
  }, [time]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        sx={{ pl: 5, pr: 5, pt: 10, pb: 5 }}
      >
        <Grid item sx={{ pb: 10 }}>
          <Typography variant="h3">
            Você pode entrar na consulta a partir de 5 minutos antes do ínicio{" "}
            da mesma. <br />
            Retorne a partir das {appointmentSoonTime} no dia {date}.
          </Typography>
        </Grid>
        <Profile name={name} cpf={cpf} /> 
        <Consultation
          date={date}
          time={time}
          doctor={doctor}
          doctorArea={doctorArea}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ pt: 10 }}
        >
          <Grid item xs={10}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("../inicio", { replace: true })}
            >
              CANCELAR
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default AppointmentTooSoon;
 