import React, { useState, useEffect } from "react";
import "./styles.css";
import { useLocation } from "react-router-dom";
import ExternalError from "../../components/ExternalError";
import WithoutId from "../../components/WithoutId";
import Loading from "../../components/Loading";
import ConfirmationDataToFace from "../../components/ConfirmationDataToFaceRegistration";
import { api } from "../../services/api";

const ConfirmationDataToFaceRegistration: React.FC = () => {
  type LocationState = {
    patientId: {
      patientId: string;
    };
  };

  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [noId, setNoId] = useState<boolean>(false);
  const [namePatient, setNamePatient] = useState<string>();
  const [cpfPatient, setCpfPatient] = useState<string>();
  const [idPatient, setIdPatient] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const { patientId } = location.state as LocationState;
 
  const searchPatientById = async () => {
    const patientRequest = { id: patientId };

    const { data: patientData } = await api.get("patient", {
      params: patientRequest,
    });
    
    if(patientData === "Error") {
      setError(true);
    }

    if (patientData.content[0]) {
      setIdPatient(patientData.content[0].id);
      setNamePatient(patientData.content[0].nome);
      setCpfPatient(patientData.content[0].documentos[0].documento);
    } else {
      setNoId(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    searchPatientById();
  }, []);

  return (
    <div className="page-confirmation">
      {loading ? ( 
        <Loading />
      ) : !loading && !noId ? (
        <ConfirmationDataToFace
          name={namePatient}
          cpf={cpfPatient}
          id={idPatient}
        />
      ) : error? (
        <ExternalError />
      ) : (
        <WithoutId />
      )}
    </div>
  );
};

export default ConfirmationDataToFaceRegistration;
