import React from "react";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import Webcam from "react-webcam";

const CaptureComponent: React.FC<any> = ({ capture, webcamRef, navigate }) => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{ pl: 10, pr: 10, pt: 10, pb: 10 }}
      >
        <Grid item>
          <Typography variant="h3" textAlign="center" sx={{ pb: 2 }}>
            Vamos verificar se você já tem cadastro!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2" textAlign="center" sx={{ pb: 2 }}>
            Para isso, precisamos fazer uma leitura biométrica facial. Tudo bem
            pra você?
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            ref={webcamRef}
            height={300}
            width={350}
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ pt: 10 }}
        >
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("../inicio", { replace: true })}
            >
              NÃO, OBRIGADO
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={capture}
            >
              SIM
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
 
export default CaptureComponent;
