import React from "react";
import "./styles.css";
import Grid from "@mui/material/Grid";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import Typography from "@mui/material/Typography";
import Busy from "../../images/Busy.png";

const BusyStatus: React.FC = () => {
  return (
    <>
      <Grid container direction="column" sx={{ pl: 3, pr: 3, pt: 3 }}>
        <Grid item>
          <Typography variant="h1">Status da Cápsula</Typography>
        </Grid>
        <Grid container alignItems="center">
          <Grid item sx={{ pr: 0.5, pt: 1 }}>
            <DoDisturbOnIcon className="image-busy" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h4">Ocupada</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        textAlign="center"
        direction="column"
        sx={{ pl: 10, pr: 10, pt: 10, pb: 10 }}
      >
        <Grid item sx={{ pb: 7 }}>
          <img src={Busy} className="photo-busy" />
        </Grid>
        <Grid item>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Olá!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2" sx={{ pb: 2 }}>
            Neste momento nossa cápsula está ocupada. Por favor, aguarde
            enquanto o atendimento é finalizado.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">
            Em breve você poderá ser atendido.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default BusyStatus;