import React from "react";
import "./styles.css";
import { Grid, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Profile: React.FC<any> = ({ name, cpf }) => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        display="flex"
        direction="row"
      >
        {/* <Grid container alignItems="start" display="flex" xs={2.8}>
          <AccountCircleIcon style={{ color: "grey", width: "120px", height: "120px" }} />
        </Grid> */}
        <Grid item xs={12}>
          <Grid container justifyContent="start" xs={12}>
            <Typography variant="h3" sx={{ pb: 2.5 }}>
              Dados encontrados
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={7.5} textAlign="left">
              <Typography variant="h1" sx={{ pb: 0.8 }}>
                Nome do paciente
              </Typography>
            </Grid>
            <Grid item xs={4.5} textAlign="left">
              <Typography variant="h1" sx={{ pb: 0.8 }}>
                CPF
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={7.5}
              textAlign="left"
              className="infoProfile"
            >
              <Typography variant="h1" sx={{ pl: 1.5, pt: 1.5, pb: 1.5 }}>
                {name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4.5}
              textAlign="left"
              className="infoProfile"
            >
              <Typography variant="h1" sx={{ pl: 1.5, pt: 1.5, pb: 1.5 }}>
                {cpf}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile
