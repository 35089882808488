import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";
import FreeStatus from "../../components/FreeStatus";
import BusyStatus from "../../components/BusyStatus";
import WaitingStatus from "../../components/WaitingStatus";
import { api } from "../../services/api";
import { socket } from "../../services/socket";

const Signin: React.FC = () => {
  const capsuleKey = localStorage.getItem('capsuleKey');
  const [status, setStatus] = useState<string>();

  useEffect(() => {
    socket.on(`status-update:${capsuleKey}`, (data) => {
      console.log('emitiu o evento')
      getCapsuleStatus();
    });
  }, []);

  const getCapsuleStatus = useCallback(async () => {
    const { data } = await api.get(`capsule/${capsuleKey}`);
    setStatus(data?.capsuleStatus.key);
    localStorage.setItem("capsuleUrl", data?.capsuleUrl)
  }, []);

  useEffect(() => {
    getCapsuleStatus();
  }, []);

  return (
    <div className="page">
      {status === "free" && <FreeStatus />}
      {status === "cleaning" && <WaitingStatus />}
      {status === "busy" && <BusyStatus />}
    </div>
  );
};

export default Signin;
