import React, { useState, useRef, useCallback } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Webcam from "react-webcam";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const CapturingFace: React.FC = () => {
  const webcamRef = useRef<Webcam | null>(null);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{ pl: 5, pr: 5, pt: 15, pb: 5 }}
      >
        <Grid item>
          <Typography variant="h3" sx={{ pb: 5 }}>
            Capturando...
          </Typography>
        </Grid>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Stack spacing={2} direction="row" style={{ position: "absolute" }}>
            <CircularProgress
              variant="determinate"
              value={progress}
              style={{ height: 90, width: 90 }}
            />
          </Stack>
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            ref={webcamRef}
            height={300}
            width={350}
            style={{ opacity: 0.4 }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CapturingFace;
