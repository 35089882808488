import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";

const ChooseSpecialty: React.FC<any> = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        direction="row"
        sx={{ pl: 5, pr: 5, pt: 20, pb: 5 }}
      >
        <Grid item xs={12} sx={{ pt: 5 }}>
          <Typography variant="h1">Escolha a especialidade</Typography>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{ pt: 10 }}
          spacing={3}
        >
          <Grid item xs={12}>
            <Grid item xs={12} sx={{ pb: 1 }}>
              <Button variant="outlined" color="primary" size="large" style={{ borderRadius: 10 }} fullWidth>
                Clínico geral
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ pb: 1 }}>
              <Button variant="outlined" color="primary" size="large" style={{ borderRadius: 10 }} fullWidth>
                Dermatologia
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ pb: 1 }}>
              <Button variant="outlined" color="primary" size="large" style={{ borderRadius: 10 }} fullWidth>
                Gastroentologia
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" color="primary" size="large" style={{ borderRadius: 10 }} fullWidth>
                Psiquiatria
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ChooseSpecialty;
