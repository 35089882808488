import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material";
import Header from "./components/Header";
import AppRoutes from "./routes";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

declare module "@mui/material" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: ["Roboto"].join(","),
      fontWeight: 500,
      fontSize: 22,
    },
    h2: {
      fontFamily: ["Roboto"].join(","),
      fontWeight: 400,
      fontSize: 22,
    },
    h3: {
      fontFamily: ["Roboto"].join(","),
      fontWeight: 600,
      fontSize: 24,
    },
    h4: {
      fontFamily: ["Roboto"].join(","),
      fontWeight: 400,
      fontSize: 25,
    },
  },

  palette: {
    primary: {
      main: "#007EA2",
    },
    secondary: {
      light: "#83cbc7",
      main: "#a3d5d8",
      dark: "#84bfc9",
      contrastText: "#02475a",
    },
    info: {
      light: "#FFFFFF",
      main: "#000000",
    },
    success: {
      main: "#27AE60",
    },
    error: {
      main: "#EB5757",
    },
    warning: {
      main: "#E2B93B",
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <AppRoutes />
    </ThemeProvider>
  );
};

export default App;