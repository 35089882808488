import React, { useState, useRef, useCallback, useEffect } from "react";
import "./styles.css";
import { useNavigate, useLocation } from "react-router-dom";
import Webcam from "react-webcam";
import CapturingFace from "../../components/CapturingFace";
import PreCaptureComponent from "../../components/PreCapture";
import { api } from "../../services/api";

const PreFaceCapture: React.FC = () => {
  type LocationState = {
    patientCpf: string;
    patientName: string;
    patientId: string;
  };

  const navigate = useNavigate();
  const location = useLocation();
  const webcamRef = useRef<Webcam | null>(null);
  const [capturing, setCapturing] = useState<boolean>(false);
  const { patientCpf, patientName, patientId } = location.state as LocationState;

  const capture = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();

      const facialRecognitionRequest = { image: imageSrc, idEmpresa: "3", patientId: patientId };
      setCapturing(true);

      await api.post("facial-recognition/save-face", facialRecognitionRequest);

      setTimeout(() => {
        navigate("../pesquisa-consulta", {
          replace: true,
          state: { patientId: patientId },
        });
      }, 9 * 1000);
    }
  };

  return (
    <div className="page-preface">
      {capturing ? (
        <CapturingFace />
      ) : (
        <PreCaptureComponent
          capture={capture}
          webcamRef={webcamRef}
          navigate={navigate}
          patientName={patientName}
          patientCpf={patientCpf}
          patientId={patientId}
        />
      )}
    </div>
  );
};

export default PreFaceCapture;
