import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Button, Typography } from "@mui/material";
import { api } from "../../services/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CarouselData from "../../components/CarouselData";

const ChooseData: React.FC = () => {
  type LocationState = {
    doctorId: string;
    name: string;
    patientId: string;
    cpf: string;
    doctorName: string;
    doctorAgenda: any;
  };

  const location = useLocation();
  const { doctorId, name, patientId, cpf, doctorName, doctorAgenda } =
    location.state as LocationState;
  const navigate = useNavigate();
  const [doctorDates, setDoctorDates] = useState<any>(doctorAgenda);
  const [dateLeft, setDateLeft] = useState<number>(0);
  const [dateMid, setDateMid] = useState<number>(1);
  const [dateRight, setDateRight] = useState<number>(2);
  const [isDisabledMinus, setIsDisabledMinus] = useState<boolean>(true);
  const [isDisabledPlus, setIsDisabledPlus] = useState<boolean>(false);
  const d = new Date();

  const changeDataMinus = () => {
    setDateLeft(dateLeft - 3);
    setDateMid(dateMid - 3);
    setDateRight(dateRight - 3);
    setIsDisabledMinus(true);
    setIsDisabledPlus(false);
  };

  const changeDataPlus = () => {
    setDateLeft(dateLeft + 3);
    setDateMid(dateMid + 3);
    setDateRight(dateRight + 3);
    setIsDisabledMinus(false);
    setIsDisabledPlus(true);
  };

  const setMonth = (arrayPos) => {
    if (
      !doctorDates[arrayPos] ||
      doctorDates[arrayPos] == null ||
      doctorDates[arrayPos] == undefined
    ) {
      return "---";
    } else {
      const dateMonth = doctorDates[arrayPos].data.slice(-5);
      const month = dateMonth.slice(0, 2);
      d.setMonth(month - 1);
      const monthName = d.toLocaleString("pt-BR", {
        month: "long",
      });
      const monthName2 = monthName.charAt(0).toUpperCase() + monthName.slice(1);
      return monthName2;
    }
  };

  const setDate = (arrayPos: any) => {
    if (
      !doctorDates[arrayPos] ||
      doctorDates[arrayPos] == null ||
      doctorDates[arrayPos] == undefined ||
      doctorDates[arrayPos].horarios.length == 0
    ) {
      return "---";
    } else {
      const dateMonth = doctorDates[arrayPos].data.slice(-5);
      const day = dateMonth.slice(-2);
      return day;
    }
  };

  const setIsDisabled = (arrayPos: any) => {
    if (
      !doctorDates[arrayPos] ||
      doctorDates[arrayPos] == null ||
      doctorDates[arrayPos] == undefined ||
      doctorDates[arrayPos].horarios.length == 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setDay = (arrayPos) => {
    if (
      !doctorDates[arrayPos] ||
      doctorDates[arrayPos] == null ||
      doctorDates[arrayPos] == undefined
    ) {
      return "---";
    } else {
      const date = new Date(doctorDates[arrayPos].data);
      const weekday = [
        "Domingo",
        "Segunda Feira",
        "Terça Feira",
        "Quarta Feira",
        "Quinta Feira",
        "Sexta Feira",
        "Sábado",
      ];

      return weekday[date.getDay() + 1];
    }
  };

  const setSelectedDoctorDates = (arrayPos) => {
    const dateMonth = doctorDates[arrayPos].data.slice(-5);
    const month = dateMonth.slice(0, 2);
    const day = dateMonth.slice(-2);
    const year = doctorDates[arrayPos].data.slice(0, 4);
    const dateMax = year + "-" + month + "-" + (parseInt(day) + 1);

    navigate("../escolher-hora", {
      replace: true,
      state: {
        doctorId: doctorId,
        dateMin: doctorDates[arrayPos].data,
        dateMax: dateMax,
        doctorHours: doctorDates[arrayPos],
        doctorAgenda: doctorAgenda,
        name: name,
        cpf: cpf,
        patientId: patientId,
        doctorName: doctorName,
      },
    });
  };

  return (
    <>
      <Grid
        display="flex"
        container
        direction="row"
        alignItems="center"
        sx={{ pt: 3, pl: 3, pr: 3, pb: 5 }}
      >
        <Grid container>
          <Button
            onClick={() =>
              navigate("../escolher-medico", {
                state: {
                  name: name,
                  patientId: patientId,
                  cpf: cpf,
                },
              })
            }
          >
            <Grid item sx={{ pr: 0.7, pt: 1 }}>
              <ArrowBackIcon fontSize="small" />
            </Grid>
            <Grid item sx={{ pt: 0.2 }}>
              <p>VOLTAR</p>
            </Grid>
          </Button>
        </Grid>

        <Grid
          display="flex"
          container
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          direction="row"
          sx={{ pl: 2, pr: 2, pt: 10, pb: 5 }}
        >
          <CarouselData
            setSelectedDoctorDates={setSelectedDoctorDates}
            doctorAgenda={doctorAgenda}
            setMonth={setMonth}
            setDate={setDate}
            setIsDisabled={setIsDisabled}
            setDay={setDay}
            dateLeft={dateLeft}
            dateRight={dateRight}
            dateMid={dateMid}
            isDisabledMinus={isDisabledMinus}
            isDisabledPlus={isDisabledPlus}
            changeDataMinus={changeDataMinus}
            changeDataPlus={changeDataPlus}
            doctorName={doctorName}
          />
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            sx={{ pt: 20 }}
          >
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                style={{ borderRadius: 10 }}
                fullWidth
                onClick={() => navigate("/inicio")}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ChooseData;