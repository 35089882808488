import React from "react";
import { Grid, Typography } from "@mui/material";

const Consultation: React.FC<any> = ({ date, time, doctor, doctorArea }) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="row"
        spacing={5} sx={{ pt: 5 }}
      >
        <Grid item textAlign="left">
          <Typography variant="h3">Consulta:</Typography>
        </Grid>
        <Grid container sx={{ pt: 2, pl: 5 }}>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={5} textAlign="left">
                <Typography variant="h1" sx={{ pb: 0.8 }}>
                  Data
                </Typography>
              </Grid>
              <Grid item xs={3} textAlign="left">
                <Typography variant="h1" sx={{ pb: 0.8 }}>
                  Hora
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={5}
                textAlign="left"
                style={{ backgroundColor: "#F0F3F4", borderRadius: 9 }}
              >
                <Typography variant="h1" sx={{ pl: 1.5, pt: 1.5, pb: 1.5 }}>
                  {date}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                textAlign="left"
                style={{ backgroundColor: "#F0F3F4", borderRadius: 9 }}
              >
                <Typography variant="h1" sx={{ pl: 1.5, pt: 1.5, pb: 1.5 }}>
                  {time}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="left">
            <Typography variant="h1" sx={{ pb: 0.8, pt: 2 }}>
              Profissional de saúde
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            textAlign="left"
            style={{ backgroundColor: "#F0F3F4", borderRadius: 9 }}
          >
            <Typography variant="h1" sx={{ pl: 1.5, pt: 1.5, pb: 1.5 }}>
              {doctor}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="left">
            <Typography variant="h1" sx={{ pb: 0.8, pt: 2 }}>
              Especialidade
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            textAlign="left"
            style={{ backgroundColor: "#F0F3F4", borderRadius: 9 }}
          >
            <Typography variant="h1" sx={{ pl: 1.5, pt: 1.5, pb: 1.5 }}>
              {doctorArea}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Consultation;
