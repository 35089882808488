import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Profile from "../Profile";

const ConfirmationDataToFace: React.FC<any> = ({ name, cpf, id }) => {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ pt: 3, pl: 3, pr: 3, pb: 10 }}
      >
        <Grid container>
          <Button onClick={() => navigate("../consulta-paciente")}>
            <Grid item sx={{ pr: 0.7, pt: 1 }}>
              <ArrowBackIcon fontSize="small" />
            </Grid>
            <Grid item sx={{ pt: 0.2 }}>
              <p>VOLTAR</p>
            </Grid>
          </Button>
        </Grid>
        <Grid container xs={12} sx={{ pt: 20 }}>
          <Profile name={name} cpf={cpf} />
          <Grid container direction="row" spacing={3} sx={{ pt: 20 }}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={() => navigate("/consulta-paciente")}
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() =>
                  navigate("../pre-captura", {
                    replace: true,
                    state: {
                      patientName: name,
                      patientCpf: cpf,
                      patientId: id,
                    },
                  })
                }
              >
                CONFIRMAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ConfirmationDataToFace;
